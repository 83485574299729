<template>
  <a-layout-header
    :style="{
      '--themeHeader': commonCache.headerTheme.backgroundColor,
      '--themeTextColor': commonCache.headerTheme.textColor,
      '--themeLineColor': commonCache.headerTheme.lineColor,
      '--loginBtnBorderColor': commonCache.headerTheme.loginBtnBorderColor,
      '--loginBtnColor': commonCache.headerTheme.loginBtnColor,
      '--registerBtnBgColor': commonCache.headerTheme.registerBtnBgColor,
      '--registerBtnColor': commonCache.headerTheme.registerBtnColor,
      'box-shadow': commonCache.headerTheme.backgroundColor === '#FFFFFF' ? '0px 1px 8px 0px rgba(173, 174, 174, 0.22)' : '',
    }"
    class="hf_header"
  >
    <div class="header_view">
      <div class="header_logo">
        <a-image :preview="false" :show-loader="true" :src="logoImg" class="header_logo_img pointer"
                 @click="useToHome()"></a-image>
        <div class="header_logo_line"></div>
        <div :lang="i18n.locale.value" class="header_logo_view">
          <p class="header_logo_text">{{ $t("header.manifesto_01") }}</p>
          <p class="header_logo_text">{{ $t("header.manifesto_02") }}</p>
        </div>
      </div>
      <div class="header_menu">
        <p
          :class="(router.currentRoute.value.path === '/' || router.currentRoute.value.path === '/pdfView')? 'nav_active':''"
          :lang="i18n.locale.value"
          class="menu_item pointer" @click="useToHome">
          {{ $t("header.menu.homePage") }}
        </p>
        <a-dropdown :popup-max-height="false" class="dropdown_course" trigger="hover">
          <div class="all_channels">
            <p
              :class="(router.currentRoute.value.path !== '/' && router.currentRoute.value.path .indexOf('/articleDetail') == -1 && router.currentRoute.value.path .indexOf('/live') == -1 && router.currentRoute.value.path !== '/dictionaries'&& router.currentRoute.value.path !== '/culture' && router.currentRoute.value.path !== '/pdfView')? 'nav_active':''"
              :lang="i18n.locale.value"
              class="menu_item pointer">
              <div class="dropdown_sty">
                {{ $t("detail.course.classify") }}
              </div>
            </p>
            <icon-down />
          </div>
          <template #content>
            <a-doption v-for="(item,index) in courseCategoryList" :key="index"
                       @click="toDetail(item)">
              {{ item.ContentName }}
            </a-doption>
          </template>
        </a-dropdown>
        <!--          <p :lang="i18n.locale.value" class="menu_item pointer" @click="navigationHandle()">
                  {{ $t('header.menu.basicChinese') }}
                </p>
                <p :lang="i18n.locale.value" class="menu_item pointer" @click="navigationHandle()">
                  {{ $t('header.menu.businessChinese') }}
                </p>
                <p :lang="i18n.locale.value" class="menu_item pointer" @click="navigationHandle()">{{ $t('header.menu.hsk') }}</p>-->
        <p :class="router.currentRoute.value.path == '/dictionaries'? 'nav_active':''" :lang="i18n.locale.value"
           class="menu_item pointer"
           @click="useToCurrentPage('/dictionaries',{},false)">
          {{ use$t("header.menu.dictionaries") }}</p>
        <p
          :class="(router.currentRoute.value.path == '/culture'  || router.currentRoute.value.path.indexOf('/articleDetail') > -1 )? 'nav_active':''"
          :lang="i18n.locale.value"
          class="menu_item pointer"
          @click="useToCurrentPage('/culture',{},false)">{{ $t("home.latest.news") }}</p>
        <p
          :class="(router.currentRoute.value.path === '/live/timeline' || router.currentRoute.value.path.indexOf( '/live/detail') > -1)? 'nav_active':''"
          :lang="i18n.locale.value"
          class="menu_item pointer" @click="useToCurrentPage('/live/timeline',{},false)">
          {{ use$t("live.message.01") }}
        </p>
      </div>
      <div class="header_right">
        <div class="search_i18n">
          <div :lang="i18n.locale.value" class="header_search">
            <template v-if="!searchInput">
              <SvgIcon v-if="commonCache.headerTheme.textColor === '#FFFFFF'" class="pointer"
                       class-name="header_search_icon" icon-class="search"
                       @click="searchInput = true"></SvgIcon>
              <SvgIcon v-else class="pointer" class-name="header_search_icon" icon-class="search_blue"
                       @click="searchInput = true"></SvgIcon>
            </template>
            <transition enter-active-class="expand-enter-active" leave-active-class="expand-leave-active" name="expand">
              <a-input-search v-if="searchInput" v-model="searchValue" :button-text="$t('header.search.btn')"
                              :placeholder="$t('form.placeholder.search')"
                              allow-clear search-button
                              @search="searchEvent" @keydown.enter="searchEvent">
                <template #button-icon>
                  <icon-search />
                </template>
              </a-input-search>
            </transition>
          </div>
          <div class="header_i18n">
            <a-dropdown v-model="selectValue" class="dropdown_i18n" trigger="hover">
              <a-row class="dropdown_show">
                <a-image v-if="selectValue === 'zh'" :preview="false" :src="zhImg" class="imageFlag"></a-image>
                <a-image v-if="selectValue === 'id'" :preview="false" :src="idImg" class="imageFlag"></a-image>
                <a-image v-if="selectValue === 'ar'" :preview="false" :src="arImg" class="imageFlag"></a-image>
                <a-image v-if="selectValue === 'en'" :preview="false" :src="enImg" class="imageFlag"></a-image>
                <a-image v-if="selectValue === 'pk'" :preview="false" :src="pkImg" class="imageFlag"></a-image>
                <a-typography-text class="languageText">{{ useGetDictKey("language", selectValue) }}</a-typography-text>
                <icon-down style="color:var(--themeTextColor)" />
              </a-row>
              <template #content>
                <a-doption v-for="(item, index) in languageArr" :key="index"
                           :class="selectValue === item.value? 'activateDropdown':''"
                           :title="item.label"
                           @click="changeLanguage(item)">
                  <template #icon>
                    <a-image v-if="item.value === 'zh'" :preview="false" :src="zhImg" class="imageFlag"></a-image>
                    <a-image v-if="item.value === 'id'" :preview="false" :src="idImg" class="imageFlag"></a-image>
                    <a-image v-if="item.value === 'ar'" :preview="false" :src="arImg" class="imageFlag"></a-image>
                    <a-image v-if="item.value === 'en'" :preview="false" :src="enImg" class="imageFlag"></a-image>
                    <a-image v-if="item.value === 'pk'" :preview="false" :src="pkImg" class="imageFlag"></a-image>
                  </template>
                  {{ item.label }}
                </a-doption>
              </template>
            </a-dropdown>
          </div>
        </div>
        <div class="header_user">
          <div v-if="!isLogin" class="header_user_btn">
            <a-button :lang="i18n.locale.value" class="login_btn" shape="round" type="outline" @click="loginHandle">
              {{ $t("header.login") }}
            </a-button>
            <a-button :lang="i18n.locale.value" class="register_btn" shape="round" status="normal" type="primary"
                      @click="registerHandle">
              {{ $t("header.register") }}
            </a-button>
          </div>
          <div v-else class="header_user_info">
            <a-dropdown class="dropdown_user" trigger="hover">
              <a-space @click="useToTabPage('/individualCenter',{tabIndex:1})">
                <a-avatar :image-url="userAvatar"></a-avatar>
              </a-space>
              <template #content>
                <a-doption :class="activateValue == 1?'activateDropdown':''"
                           @click="useToTabPage('/individualCenter',{tabIndex:1})">{{ $t("individual.center")
                  }}
                </a-doption>
                <a-doption :class="activateValue == 2?'activateDropdown':''"
                           @click="useToTabPage('/individualCenter',{tabIndex:2})">
                  {{ $t("individual.center.my.homework")
                  }}
                </a-doption>
                <a-doption :class="activateValue == 3?'activateDropdown':''"
                           @click="useToTabPage('/individualCenter',{tabIndex:3})">{{ $t("individual.center.my.course")
                  }}
                </a-doption>
                <a-doption :class="activateValue == 4?'activateDropdown':''"
                           @click="useToTabPage('/individualCenter',{tabIndex:4})">
                  {{ $t("individual.center.my.collection") }}
                </a-doption>
                <a-doption @click="exitHandle">
                  {{ $t("individual.center.log.out") }}
                </a-doption>
              </template>
            </a-dropdown>
          </div>
        </div>
      </div>
    </div>
  </a-layout-header>
  <!--  登录  -->
  <Login v-model:forget-visible="forgetVisible" v-model:login-visible="loginVisible"
         v-model:register-visible="registerVisible" />
  <!-- 注册 -->
  <Register v-model:login-visible="loginVisible" v-model:register-visible="registerVisible" />
  <!-- 忘记密码 -->
  <ForgetPassword v-model:forget-visible="forgetVisible" v-model:login-visible="loginVisible" />
</template>

<script setup>
import logo_white from "@/assets/images/logo_white.gif";
import logo_black from "@/assets/images/logo_black.gif";
import { useCommonCacheStore } from "@/stores/commonCache.js";
import { computed, onMounted, reactive, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import Login from "@/components/login/index.vue";
import Register from "@/components/register/index.vue";
import ForgetPassword from "@/components/forgetPassword/index.vue";
import { useI18n } from "vue-i18n";
import { useLocalCacheStore } from "@/stores/localCache.js";
import { dict, useGetDictKey } from "@/hooks/sysDict.js";
import { use$t, useChangeLanguage } from "@/hooks/language.js";
import { useToCurrentPage, useToHome, useToTabPage } from "@/hooks/page.js";
import request from "@/common/request.js";
import Confirm from "@/components/common/Confirm/index.js";
import img from "@/components/layout/images/header.png";
import { useCheckIsLogin, useCourseCategoryApi } from "@/hooks/commomApi.js";
import BusinessContextEvent from "@/common/BusinessContextEvent.js";
import earth from "./images/earth.gif";
import zhImg from "./images/zh.svg";
import idImg from "./images/id.svg";
import arImg from "./images/ar.svg";
import enImg from "./images/en.svg";
import pkImg from "./images/pk.svg";
import { getUrlParams } from "@/hooks/utils.js";

const commonCache = useCommonCacheStore();
const router = useRouter();
const route = useRoute();
const loginVisible = ref(false);
const registerVisible = ref(false);
const forgetVisible = ref(false);
const i18n = useI18n();
const localCache = useLocalCacheStore();
const courseCategoryList = reactive([]);
const searchValue = ref("");

const searchInput = ref(false);

const isLogin = computed(() => {
  return (localCache.userinfo != null || localCache.userinfo != undefined);
});

const activateValue = computed(() => {
  if (route.path.indexOf("/individualCenter") > -1 && route.query.hasOwnProperty("tabIndex")) {
    return route.query.tabIndex;
  }
  return "";
});

const userAvatar = computed(() => {
  if (localCache.userinfo?.UserIcon == null) {
    return img;
  }
  return import.meta.env.VITE_APP_IMG_BASE_URL + localCache.userinfo?.UserIcon;
});


const toDetail = (item) => {
  if (item.Id == "") {
    useToTabPage("/allCourse");
  } else {
    useToTabPage("/allCourse", { categoryId: item.Id });
  }
};

/**
 * 搜索课程
 */
const searchEvent = () => {
  if (searchValue.value === "") {
    router.replace({
      path: "/allCourse"
    });
  }
  router.push({
    path: "/allCourse",
    query: {
      searchValue: searchValue.value
    }
  });
};

const logoImg = computed(() => {
  if (commonCache.headerTheme.textColor === "#FFFFFF") {
    return logo_white;
  } else {
    return logo_black;
  }
});

const changeLanguage = (item) => {
  useChangeLanguage(item.value);
};

const languageArr = computed(() => {
  return dict.language;
});

const selectValue = computed(() => {
  return localCache.defaultLanguage;
});

const loginHandle = () => {
  loginVisible.value = true;
};


const exitHandle = () => {
  Confirm({
    title: use$t("individual.center.log.out"),
    content: use$t("individual.center.logout.confirm"),
    okEvent: async () => {
      request({
        url: "/Auth/LogOut",
        method: "get",
        params: {}
      }).then((resp) => {
        localCache.setUserInfo(undefined);
        localStorage.removeItem("Token");
        useToHome();
        return true;
      });
    }
  });
};
const registerHandle = () => {
  registerVisible.value = true;
};

onMounted(async () => {
  let courseCategory = await useCourseCategoryApi();
  courseCategoryList.length = 0;
  courseCategoryList.push({
    Id: "",
    ContentName: use$t("common.all.course")
  });
  courseCategory.data.forEach(item => {
    courseCategoryList.push({
      Id: item.Id,
      ContentName: item.ContentName
    });
  });

  /**
   * 注册打开登录弹框
   */
  BusinessContextEvent.$off("loginHandle").$on("loginHandle", () => {
    loginHandle();
  });

  const userCode = getUrlParams("userCode");
  if (userCode) {
    useCheckIsLogin(() => {
    }, false, () => {
      registerHandle();
    });
    //存储注册码
    localStorage.setItem("USER_CODE", userCode);
  }
});

watch(() => router.currentRoute.value, (value, oldValue, onCleanup) => {
  if (value.query.hasOwnProperty("searchValue")) {
    searchInput.value = true;
    searchValue.value = value.query.searchValue;
  } else {
    searchInput.value = false;
    searchValue.value = "";
  }
}, { immediate: true });


</script>

<style lang="less" scoped>
.hf_header {
  width: 100vw;
  height: 88px;
  position: fixed;
  z-index: 2;
  background-color: var(--themeHeader);

  .header_view {
    padding: 0 50px;
    background-color: transparent;
    height: 88px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header_logo {
      display: flex;
      align-items: center;

      .header_logo_line {
        width: 1px;
        height: 47px;
        background: #ffffff;
        opacity: 0.6;
        margin-left: 11px;
        margin-right: 11px;
      }

      .header_logo_img {
        :deep(.arco-image-img) {
          width: 160px !important;
          height: 47px !important;
        }
      }

      .header_logo_view {
        min-width: 97px;

        .header_logo_text {
          font-family: PingFang SC;
          white-space: nowrap;
          font-weight: 400;
          margin: 0;
          font-size: 15px;
          color: var(--themeTextColor);
          line-height: 21px;
          font-style: normal;
        }
      }
    }

    .header_menu {
      flex-grow: 1;
      display: flex;

      .all_channels {
        display: flex;
        align-items: center;
        cursor: pointer;

        .menu_item {
          margin-right: 0 !important;
        }

        .arco-icon-down {
          transition: transform 0.3s ease-in-out;
          margin-left: 5px;
          margin-top: -5px;
          font-weight: bold;
          width: 20px;
          height: 20px;
          color: var(--themeTextColor);
        }
      }

      .arco-dropdown-open .arco-icon-down {
        transform: rotate(180deg);
      }

      .menu_item {
        font-family: PingFang SC;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-weight: 400;
        font-size: 15px;
        margin: 0 20px;
        color: var(--themeTextColor);
        text-align: center;
        font-style: normal;

        .dropdown_sty {
          display: flex;
          align-items: center;
        }

        &:lang(en) {
          margin: 0 10px;
        }

        &:after {
          content: '';
          width: 100%;
          height: 3px;
          background-color: var(--themeLineColor);
          display: inline-block;
          border-radius: 2px;
          position: relative;
          top: 5px;
          transform-origin: center;
          transform: scaleX(0);
          transition: transform 0.5s ease-out;
        }

        &:hover {
          &:not(.nav_active) {
            &::after {
              transform: scaleX(1); /* 宽度增加到100% */
            }
          }
        }
      }

      .nav_active {
        &:after {
          transform: scaleX(1);
        }
      }
    }

    .header_right {
      display: flex;
      align-items: center;

      .search_i18n {
        display: flex;
        align-items: center;
        margin-left: auto;

        .header_search {
          display: flex;
          justify-content: flex-end;
          width: 300px;
          margin-left: 50px;

          &:lang(ar), &:lang(pk) {
            margin-left: 0;
          }

          .header_search_icon {
            width: 30px;
            height: 30px;
          }

          .arco-input-search {
            padding: 2px;
            height: 35px;
            border: 1px solid #0678ff;
            background-color: #FFFFFF !important;
            border-radius: 6px;

            :deep(.arco-input-wrapper) {
              background-color: #FFFFFF !important;
              border: 0;
            }

            .arco-input-append {
              margin: 0;
            }

            :deep(.arco-input-search-btn) {
              border-radius: 6px;
              height: 35px;
            }
          }

          .expand-enter-active,
          .expand-leave-active {
            width: 400px;
            transition: width 0.3s linear;
          }

          .expand-enter-from,
          .expand-leave-to {
            width: 200px;
          }
        }

        .header_i18n {
          margin-left: 22px;
          margin-right: 22px;

          &:focus-visible {
            outline: none !important;
          }

          .header_i18n_icon {
            width: 55px;
            height: 55px;
            cursor: pointer;

            &:focus {
              outline: none !important;
            }
          }
        }
      }

      .header_user {
        .header_user_btn {
          display: flex;

          .login_btn {
            width: 100px;
            height: 40px;
            border-radius: 21px;
            border: 1px solid var(--loginBtnBorderColor);
            font-family: PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: var(--loginBtnColor);
            line-height: 40px;
            text-align: center;
            font-style: normal;

            &:lang(ar), &:lang(pk) {
              width: auto;
            }
          }

          .register_btn {
            width: 100px;
            height: 40px;
            margin-left: 20px;
            background-color: var(--registerBtnBgColor);
            border-radius: 21px;
            border: 0;
            backdrop-filter: blur(10px);
            font-family: PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: var(--registerBtnColor);
            line-height: 40px;
            text-align: center;
            font-style: normal;

            &:lang(ar), &:lang(pk) {
              width: auto;
              margin-right: 20px;
            }
          }
        }

        .header_user_info {
          cursor: pointer;

          :deep(.arco-typography) {
            font-family: PingFang SC;
            font-weight: 400;
            font-size: 18px;
            color: var(--themeTextColor);
            line-height: 25px;
            text-align: right;
            font-style: normal;
          }
        }
      }
    }
  }

  .imageFlag {
    width: 23px;

    :deep(.arco-image-img) {
      width: 23px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .arco-dropdown-open .arco-icon-down {
    transform: rotate(180deg);
  }
}
</style>
