// src/main.js
import { createI18n } from 'vue-i18n';
import en from '@/locales/lang/en.js';
import zh from '@/locales/lang/zh.js';
import id from '@/locales/lang/id.js';
import ar from '@/locales/lang/ar.js';
import pk from '@/locales/lang/pk.js';

const i18n = createI18n({
  legacy: false, // 设置为 false，启用 composition API 模式
  fallbackLocale: 'en',
  locale: 'en', // 设置默认语言
  warnHtmlMessage: false,
  messages: {
    en: en,
    zh: zh,
    id: id,
    ar: ar,
    pk: pk,
  },
});

export default i18n;
