<template>
  <a-layout-footer>
    <div ref="activityRef" :lang="i18n.locale.value" :style="{ '--activityI18nBgImg': activityI18nBgImg }" class="activity">
      <a-image :preview="false" :src="activity_people" class="people_view anime_zoom_lager"></a-image>
      <SvgIcon :lang="i18n.locale.value" class-name="activity_icon" icon-class="close" @click="removeDom" />
      <a-image :preview="false" :src="btnImage" @click="freeCourseEvent" />
    </div>
    <div :lang="i18n.locale.value" class="hf_footer">
      <div class="footer_basic_info">
        <div class="w1200 footer_basic_info_view">
          <div class="footer_basic_info_logo">
            <div class="footer_logo">
              <a-image :preview="false" :src="logoWhite" />
              <div class="footer_line"></div>
              <div class="footer_logo_text">
                <p>{{ $t('header.manifesto_01') }}</p>
                <p>{{ $t('header.manifesto_02') }}</p>
              </div>
            </div>
            <div class="footer_desc">{{ $t('footer.unit.desc') }}</div>
          </div>
          <div :lang="i18n.locale.value" class="footer_basic_info_navi">
            <div class="navi_item">
              <h3>{{ $t('footer.chinese.course') }}</h3>
              <p v-for="(item, index) in courseCategoryList" :key="index" @click="useToTabPage('/allCourse', { categoryId: item.Id })">{{ item.ContentName }}</p>
            </div>
            <div class="navi_item">
              <h3>{{ $t('footer.about.HanFeng') }}</h3>
              <p @click="aboutEvent">{{ $t('footer.about.us') }}</p>
              <p @click="useToTabPage('/agreement')">{{ $t('footer.privacy.agreement') }}</p>
              <p @click="feedbackEvent">{{ $t('footer.problem.feedback') }}</p>
            </div>
            <div class="navi_item">
              <h3>{{ $t('footer.global.consulting') }}</h3>
              <p>{{ $t('footer.report.mailbox') }}</p>
              <p>{{ useGetEnv('VITE_APP_EMAIL') }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="archival_info">
        <div>{{ $t('footer.copyright', { value: $t('footer.copyright.text') }) }}</div>
        <div>电信业务经营许可证编号：皖B2-20240386</div>
      </div>
    </div>
  </a-layout-footer>
  <Feedback v-model:feedback-visible="feedbackVisible" />
</template>
<script setup>
import logoWhite from '@/assets/images/logo_white.gif';
import btn_zh from './images/btn_zh.gif';
import btn_en from './images/btn_en.gif';
import btn_id from './images/btn_id.gif';
import btn_ar from './images/btn_ar.gif';
import btn_pk from './images/btn_pk.gif';
import activity_people from './images/activity_people.png';
import Feedback from '@/components/feedback/index.vue';
import { computed, onMounted, reactive, ref } from 'vue';
import { useCheckIsLogin, useCourseCategoryApi } from '@/hooks/commomApi.js';
import { useI18n } from 'vue-i18n';
import { useToCurrentPage, useToOtherPage, useToTabPage } from '@/hooks/page.js';
import { langToBack } from '@/hooks/sysDict.js';
import activity_zh from './images/activity_zh.png';
import activity_pk from './images/activity_pk.png';
import activity_en from './images/activity_en.png';
import activity_id from './images/activity_id.png';
import activity_ar from './images/activity_ar.png';
import { useGetEnv } from '../../hooks/utils.js';
import { useLocalCacheStore } from '@/stores/localCache.js';

const courseCategoryList = reactive([]);
const i18n = useI18n();
const localCache = useLocalCacheStore();

const feedbackVisible = ref(false);
const activityRef = ref();

const activityI18nBgImg = computed(() => {
  if (i18n.locale.value == langToBack.zh) {
    return `url(${activity_zh})`;
  } else if (i18n.locale.value == langToBack.en) {
    return `url(${activity_en})`;
  } else if (i18n.locale.value == langToBack.id) {
    return `url(${activity_id})`;
  } else if (i18n.locale.value == langToBack.ar) {
    return `url(${activity_ar})`;
  } else if (i18n.locale.value == langToBack.pk) {
    return `url(${activity_pk})`;
  }
});

const btnImage = computed(() => {
  if (i18n.locale.value == langToBack.zh) {
    return btn_zh;
  } else if (i18n.locale.value == langToBack.en) {
    return btn_en;
  } else if (i18n.locale.value == langToBack.id) {
    return btn_id;
  } else if (i18n.locale.value == langToBack.ar) {
    return btn_ar;
  } else if (i18n.locale.value == langToBack.pk) {
    return btn_pk;
  }
});

const removeDom = () => {
  activityRef.value.remove();
};

/**
 * 问题反馈
 */
const feedbackEvent = () => {
  useCheckIsLogin(() => {
    feedbackVisible.value = true;
  });
};

/**
 * 免费课程
 */
const freeCourseEvent = () => {
  useToTabPage('/allCourse', {
    feeType: '2',
  });
};

const aboutEvent = () => {
  useToCurrentPage('/pdfView');
};

onMounted(async () => {
  let courseCategory = await useCourseCategoryApi();
  courseCategoryList.length = 0;
  courseCategory.data.forEach((item, index) => {
    if (index < 4) {
      courseCategoryList.push({
        Id: item.Id,
        ContentName: item.ContentName,
      });
    }
  });
});
</script>

<style lang="less" scoped>
:deep(.arco-layout-footer) {
  padding-top: 100px;
}

.hf_footer {
  .footer_basic_info {
    width: 100vw;
    height: 380px;
    background-color: #333333;

    .footer_basic_info_view {
      margin: 0 auto;
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: space-between;

      .footer_basic_info_logo {
        .footer_logo {
          display: flex;

          :deep(.arco-image-img) {
            width: 170px;
            height: 48px;
          }

          .footer_line {
            margin-left: 12px;
            width: 1px;
            height: 50px;
            background-color: rgba(255, 255, 255, 0.6);
            margin-right: 12px;
          }

          .footer_logo_text {
            p {
              font-family: PingFang SC;
              font-weight: 400;
              font-size: 16px;
              color: rgb(255, 255, 255);
              line-height: 22px;
              letter-spacing: 1px;
              text-align: left;
              font-style: normal;
              margin: 0;
            }
          }
        }

        .footer_desc {
          width: 294px;
          font-family: PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #d4d4d4;
          line-height: 20px;
          font-style: normal;
          margin-top: 40px;
        }
      }

      .footer_basic_info_navi {
        display: flex;

        .navi_item {
          h3 {
            font-family: PingFang SC;
            font-weight: 600;
            font-size: 18px;
            color: #ffffff;
            line-height: 25px;
            letter-spacing: 1px;
            font-style: normal;
          }

          p {
            font-family: PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: #d4d4d4;
            line-height: 22px;
            letter-spacing: 1px;
            font-style: normal;
            cursor: pointer;
            margin-top: 20px;
          }

          &:nth-child(2) {
            margin-left: 90px;
          }

          &:nth-child(3) {
            margin-left: 90px;
          }
        }

        &:lang(en) {
          .navi_item {
            &:nth-child(2) {
              margin-left: 20px;
            }

            &:nth-child(3) {
              margin-left: 20px;
            }
          }
        }
      }
    }
  }

  .archival_info {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    background-color: #282828;
    font-family: PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    line-height: 80px;
    text-align: center;
    font-style: normal;

    div {
      padding: 0 5px;
    }
  }

  &:lang(ar),
  &:lang(pk) {
    .navi_item {
      &:nth-child(1) {
        margin-left: 0;
        margin-right: 90px;
      }

      &:nth-child(2) {
        margin-left: 0;
        margin-right: 90px;
      }
    }
  }
}

.activity {
  box-shadow: 0 10px 10px 10px rgb(238, 49, 43, 0.8);
  height: 120px;
  background-image: var(--activityI18nBgImg);
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .people_view {
    width: 156px;
    height: 217px;
    float: left;
    bottom: 49px;
    left: 280px;

    :deep(.arco-image-img) {
      width: 156px;
      height: 217px;
    }
  }

  &:lang(ar),
  &:lang(pk) {
    .people_view {
      float: right;
      left: auto;
      right: 280px;
      margin: 0;
    }

    :deep(.arco-image) {
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      margin-left: 250px;
      cursor: pointer;
    }
  }

  :deep(.arco-image) {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    margin-right: 250px;
    cursor: pointer;
  }

  :deep(.arco-image-img) {
    width: 200px;
  }

  .activity_icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
    position: absolute;
    right: 10px;

    &:lang(ar),
    &:lang(pk) {
      right: auto;
      left: 10px;
    }
  }
}
</style>
