<!--密码重置-->
<template>
  <a-modal
    v-model:visible="forgetVisible"
    :closable="true"
    :esc-to-close="false"
    :lang="i18n.locale.value"
    :mask-closable="true"
    :unmount-on-close="false"
    modal-class="forget_modal"
    @before-close="beforeClose"
  >
    <p class="welcome">{{ $t('form.reset.password') }}</p>
    <p class="welcome_desc">{{ $t('login.welcome.desc') }}~</p>
    <a-form ref="formRef" :model="formParams" :rules="rules" layout="vertical" @submit="handleSubmit">
      <a-form-item field="email" validate-trigger="blur">
        <a-input v-model="formParams.email" :placeholder="$t('form.placeholder.email')" allow-clear />
      </a-form-item>
      <a-form-item field="vCode" validate-trigger="blur">
        <a-input-search
          v-model="formParams.vCode"
          :button-text="buttonText"
          :class="buttonDisabled ? 'buttonDisabled' : ''"
          :loading="loading_01"
          :max-length="6"
          :placeholder="$t('form.placeholder.code')"
          search-button
          @search="getEmailCode"
        />
      </a-form-item>
      <a-form-item field="password" validate-trigger="blur">
        <a-input-password v-model="formParams.password" :placeholder="$t('form.placeholder.password')" allow-clear />
      </a-form-item>
      <a-form-item field="confirmPassword" validate-trigger="blur">
        <a-input-password v-model="formParams.confirmPassword" :placeholder="$t('form.placeholder.confirm.password')" allow-clear />
      </a-form-item>
      <a-form-item class="forget_now">
        <a-button class="forget_now_btn" html-type="submit">{{ $t('form.reset.password') }}</a-button>
      </a-form-item>
    </a-form>
    <template #footer>
      <p class="no_account">
        {{ $t('reset.come.mind') }}？
        <span @click="forgetNow">{{ $t('login.sign.now') }}</span>
      </p>
    </template>
  </a-modal>
</template>

<script setup>
import { computed, reactive, ref } from 'vue';
import { cloneDeep } from 'lodash';
import { use$t } from '@/hooks/language.js';
import RegUtils from '@/common/RegUtils.js';
import request from '@/common/request.js';
import Alert from '@/components/common/Alert/index.js';
import { langToBack } from '@/hooks/sysDict.js';
import { useI18n } from 'vue-i18n';

const formRef = ref();
const loading_01 = ref(false);
const loading_02 = ref(false);
const buttonText = ref(use$t('register.fetch.code'));
const buttonDisabled = ref(false);
const i18n = useI18n();

const $props = defineProps({
  loginVisible: {
    type: Boolean,
    default: false,
  },
  forgetVisible: {
    type: Boolean,
    default: false,
  },
});
const $emits = defineEmits(['update:loginVisible', 'update:forgetVisible']);

const loginVisible = computed({
  get: () => {
    return $props.loginVisible;
  },
  set: (val) => {
    $emits('update:loginVisible', val);
  },
});
const forgetVisible = computed({
  get: () => {
    return $props.forgetVisible;
  },
  set: (val) => {
    $emits('update:forgetVisible', val);
  },
});

const formParams = reactive({
  email: '',
  vCode: '',
  password: '',
  confirmPassword: '',
});

const copyFormParams = reactive(cloneDeep(formParams));

const rules = reactive({
  email: [
    {
      required: true,
      message: use$t('form.placeholder.email'),
    },
    {
      validator: (value, cb) => {
        if (RegUtils.RegEmail(value)) {
          cb(use$t(use$t('form.email.validate')));
        } else {
          cb();
        }
      },
    },
  ],
  vCode: [
    {
      required: true,
      message: use$t('form.placeholder.code'),
    },
  ],
  password: [
    {
      required: true,
      message: use$t('form.placeholder.password'),
    },
    {
      validator: (value, cb) => {
        if (RegUtils.RegPw(value)) {
          cb(use$t(use$t('form.password.validate')));
        } else {
          cb();
        }
      },
    },
  ],
  confirmPassword: [
    {
      required: true,
      message: use$t('form.placeholder.confirm.password'),
    },
    {
      validator: (value, cb) => {
        if (value !== formParams.password) {
          cb(use$t('form.password.unlikeness'));
        } else if (RegUtils.RegPw(value)) {
          cb(use$t(use$t('form.password.validate')));
        } else {
          cb();
        }
      },
    },
  ],
});

const handleSubmit = ({ errors, values }) => {
  if (errors) return;
  request({
    url: '/Auth/RetrievePwd',
    method: 'get',
    params: {
      email: formParams.email,
      newpwd: formParams.password,
      vcode: formParams.vCode,
    },
  }).then((resp) => {
    Alert({
      title: use$t('code.1013'),
      content: use$t('common.reset.password'),
      okText: use$t('login.sign.now'),
      okEvent: () => {
        forgetNow();
      },
    });
  });
};

const forgetNow = () => {
  forgetVisible.value = false;
  loginVisible.value = true;
};

const beforeClose = () => {
  Object.assign(formParams, copyFormParams);
};

/**
 * 获取邮箱验证码
 */
const getEmailCode = () => {
  if (buttonDisabled.value === true) {
    return;
  }
  formRef.value.validateField('email', (errors) => {
    if (errors) return;
    loading_01.value = true;
    request({
      url: '/Auth/EmailVerification',
      method: 'get',
      params: {
        email: formParams.email,
        oper: 1,
        lang: langToBack[i18n.locale.value],
      },
    })
      .then((resp) => {
        loading_01.value = false;
        let count = 60;
        buttonDisabled.value = true;
        buttonText.value = count + ' s';
        let interval = window.setInterval(() => {
          buttonText.value = (--count).toString() + ' s';
          if (count <= 0) {
            window.clearInterval(interval);
            buttonDisabled.value = false;
            buttonText.value = use$t('register.fetch.code');
          }
        }, 1000);
      })
      .catch((err) => {
        loading_01.value = false;
        console.log(err);
      });
  });
};
</script>

<style lang="less">
.forget_modal {
  width: 600px;
  border-radius: 8px;
  background-image: url('@/assets/images/login_bg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .arco-modal-header {
    justify-content: flex-end;
    border-bottom: 0;

    .arco-icon-close {
      color: #999999;
      width: 22px;
      height: 22px;
    }
  }

  .arco-modal-body {
    padding: 8px 60px;

    .welcome {
      font-family: PingFang SC;
      font-weight: 600;
      font-size: 34px;
      color: #000000;
      line-height: 48px;
      text-align: left;
      font-style: normal;
    }

    .welcome_desc {
      font-family: PingFang SC;
      font-weight: 400;
      font-size: 18px;
      color: #000000;
      line-height: 25px;
      text-align: left;
      font-style: normal;
      margin-top: 20px;
    }

    .arco-form {
      margin-top: 30px;

      .arco-row-align-start {
        height: 48px;
        margin: 0;

        .arco-btn {
          height: 100%;
          min-width: 100px;
          border-radius: 0 6px 6px 0;
        }

        &:nth-child(n + 2) {
          margin-top: 20px;
        }
      }

      .arco-input-wrapper {
        width: 100vw;
        height: 48px;
        background: #ffffff;
        border-radius: 6px;
        border: 1px solid #cdcdcd;
      }

      .arco-input-search {
        .arco-input-wrapper {
          border-radius: 6px 0 0 6px;
          border-right: 0;
        }
      }

      .forget_now {
        .forget_now_btn {
          width: 100vw;
          height: 48px;
          background: linear-gradient(270deg, #0eb0ff 0%, #0678ff 100%);
          border-radius: 6px;
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #ffffff;
          line-height: 22px;
          text-align: center;
          font-style: normal;
        }
      }
    }

    .arco-form-item-label-col {
      display: none;
    }
  }

  .arco-modal-footer {
    border: 0;
    margin-top: 40px;

    .no_account {
      font-family: PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #999999;
      line-height: 20px;
      text-align: center;
      font-style: normal;

      span {
        color: #0678ff;
        cursor: pointer;
      }
    }
  }

  &:lang(ar),
  &:lang(pk) {
    .arco-form {
      .arco-input-search-btn {
        border-radius: 6px 0 0 6px;
      }
    }

    .arco-input-search {
      .arco-input-wrapper {
        border-radius: 0 6px 6px 0 !important;
      }
    }
  }
}

.buttonDisabled {
  .arco-input-append {
    .arco-input-search-btn {
      background-color: #999999 !important;

      &:before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        transform: unset;
        transition: unset;
      }

      &:hover {
        background-color: #999999 !important;
        animation: unset !important;

        &::before {
          transform: unset;
        }
      }
    }
  }
}
</style>
