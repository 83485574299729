import FileSaver from 'file-saver';
import { loadScript } from '@paypal/paypal-js';

/**
 * 下载文件
 * @param url
 * @param filename
 */
export function useDownloadFile(url, filename = '') {
  FileSaver.saveAs(url, filename);
}

/**
 * 更新视频源
 * @param player
 * @param newSrc
 */
export function useUpdateVideoSource(player, newSrc) {
  player.src({
    src: newSrc,
    type: 'video/mp4', // 或者其他视频格式
    provider: 'video',
  });
  // 播放新的视频
  player.load();
  player.play();
}

/**
 * 解析富文本
 * @param html
 * @returns {*}
 */
export function useAnalyzeHtml(html) {
  return html;
}

/**
 * 处理音频
 * @param el
 */
export function useSelectAudioHtml(el) {}

/**
 * 动态获取资源
 * @param url
 * @returns {string}
 */
export function useGetAssetsFile(url) {
  return new URL(url, import.meta.url).href;
}

/**
 * 获取环境变量
 * @param key
 * @returns {*}
 */
export function useGetEnv(key) {
  return import.meta.env[key];
}

/**
 * 获取页面url参数
 * @returns {{}}
 */
export function getUrlParams(name) {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
  const r = window.location.search.substr(1).match(reg) || window.location.hash.substring(window.location.hash.search(/\?/) + 1).match(reg);
  if (r != null) {
    return decodeURIComponent(r[2]);
  }
}

/**
 * paypal处理方法
 * @param $el
 * @param $el
 * @param createOrder
 * @param onCancel
 * @param onError
 */
export function useInitPaypal($el, createOrder, onSuccess, onCancel, onError) {
  loadScript({
    // environment: useGetEnv('VITE_APP_ENV') === 'production' ? 'production' : 'sandbox', //"production" | "sandbox"
    'client-id': import.meta.env.VITE_APP_PAYPAL_CLIENT_ID,
    commit: true,
    components: 'buttons,messages',
    currency: 'USD',
    debug: false,
  })
    .then((paypal) => {
      console.log('paypal===>', paypal);
      // start to use the PayPal JS SDK script
      if (paypal) {
        paypal
          .Buttons({
            style: {
              layout: 'vertical',
              color: 'gold',
              shape: 'rect',
              Tagline: 'false',
              label: 'buynow',
            },
            async createOrder() {
              return await createOrder();
            },
            async onApprove(data) {
              console.log('支付成功', data);
              let xAuthToken = localStorage.getItem('Token');
              // Capture the funds from the transaction.
              const response = await fetch(useGetEnv('VITE_APP_PAYPAL_DOMAIN') + 'PayOrders/success_pay?token=' + data.orderID + '&payerId=' + data.payerID, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + xAuthToken },
              });

              const details = await response.json();
              console.log('支付成功', details);
              onSuccess && onSuccess(details);
              //
              // // Show success message to buyer
              // alert(`Transaction completed by ${details.payer.name.given_name}`);
            },
            onCancel(data) {
              // 取消支付
              // window.location.assign("/your-cancel-page");
              console.log('我取消了支付', data);
              onCancel && onCancel(data);
            },
            onError(err) {
              // 支付出现错误
              // window.location.assign('/your-error-page-here');
              console.log('支付出错了', err);
              onError && onError(err);
            },
          })
          .render($el);
      }
    })
    .catch((err) => {
      console.error('failed to load the PayPal JS SDK script', err);
    });
}
