// 定义印尼语言包对象
export default {
  //公共部分
  'common.cancel': 'batal',
  'common.confirm': 'OK',
  'common.picture.cropping': 'Edit Foto',
  'common.course.tags': 'Catatatan Pelajaran',
  'common.all.course': 'Semua pelajaran',
  'common.article.detail': 'Keterangan artikel',
  'common.article.public.date': 'Tanggal publikasi',
  'common.article.pre': 'Yang sebelumnya',
  'common.article.next': 'Yang berikutnya',
  'common.reset.password': 'Kata sandi telah direset, silahkan login kembali',
  'common.back.home': 'Balik ke halaman utama',
  'common.practice.after.class': 'Latihan setelah pelajaran',
  'common.practice.no.after.class': 'Tidak ada latihan setelah pelajaran',
  'common.next.course': 'Mata Pelajaran berikutnya',
  'common.pre.course': 'Mata Pelajaran sebelumnya',
  'common.stop.course': 'Berhenti',
  'common.homework.title': 'Tugas sudah di kirim',
  'common.homework.teacher.comments': 'Komentar Guru',
  'common.all.course.charge': 'Bayar',
  'common.all.course.free': 'Gratis',
  'common.read.agreement': 'Silahkan membaca dan periksa perjanjian pengguna',
  'common.protokol.privasi01': `Saya sudah baca dan menyetujui`,
  'common.protokol.privasi02': `《Perjanjian pengguna》`,
  //头部
  'header.manifesto_01': 'Belajar bahasa Mandarin',
  'header.manifesto_02': 'Han Zhi Feng ',
  'header.menu.homePage': 'Halaman pertama',
  'header.menu.dictionaries': 'Kamus',
  'header.menu.allChannels': 'Semua',
  'header.login': 'Login',
  'header.register': 'Daftar',
  'header.search.btn': 'Cari',
  //首页
  'home.ourAdvantage': 'Keunggulan kami',
  'home.hotline': 'Email layanan pelanggan',
  'home.contact.immediately': 'Segera hubungi',
  'home.back.top': 'Kembali ke atas',
  'home.view.now': 'Segera mengecek pelajaran  yang berkualitas  ',
  'home.learn.count': ' orang belajar di HanZhiFeng',
  'home.unit.desc':
    'Kami adalah sebuah Platform  pengajaran bahasa Mandarin profesional yang bersifat Internasional. Mempunyai konsep "Berorientasi kepada siswa, mengutamakan kwalitas". Membantu menyebarkan kebudayaan China. Merupakan tulang punggung pengajaran bahasa Mandarin didalam dan luar negeri, pertukaran kebudayaan  antara China dan luar negeri, serta penyiaran kebudayaan China.',
  'home.data.statistics': 'Data statistik tersebut berasal dari HanZhiFeng',
  'home.recommended.course': 'Pelajaran yang disarankan',
  'home.recommended.cost-effective': 'kinerja yang tinggi',
  'home.recommended.high.quality': 'Kualitas tinggi',
  'home.go.to': 'Segera lanjut',
  'home.learned.count': '{value} orang sudah belajar',
  'home.course.more': 'Saya masih ingin belajar',
  'home.change.course': 'Tukar ',
  'home.price.free': 'Gratis',
  //底部
  'footer.unit.desc':
    'Berkonsentrasi pada pengajaran bahasa mandarin dan pengenalan kebudayaan bertaraf Internasional  yang berbasis e learning. Hingga saat ini sudah mendidik murid dari 110 lebih negara dan 10.000 siswa asing beberapa daerah yang datang belajar.',
  'footer.chinese.course': 'Mata pelajaran Mandarin',
  'footer.about.HanFeng': 'Tentang Han Zhi Feng',
  'footer.about.us': 'Tentang Kami',
  'footer.privacy.agreement': 'Perjanjian pengguna',
  'footer.problem.feedback': 'Laporan permasalahan',
  'footer.global.consulting': 'Konsultasi/ pengaduan terpadu',
  'footer.report.mailbox': 'Email layanan pelanggan',
  'footer.copyright': 'Copyright © 2024 安徽汉之风教育科技有限公司版权所有 {value}：皖ICP备2024055290号',
  'footer.copyright.text': `Nomor regisrasi web`,
  //个人中心
  'individual.center': 'Pengaturan Pribadi',
  'individual.center.my.homework': 'Tugas Saya',
  'individual.center.my.course': 'Jadwal Pembelajaran Saya',
  'individual.center.my.collection': 'Koleksi Saya',
  'individual.center.log.out': 'Keluar dari akun',
  'individual.center.logout.confirm': 'Apakah yakin keluar?',
  'individual.update.password': 'Ubah password',
  'individual.center.nickname': 'Username',
  'individual.center.submit': 'Submit',
  'individual.center.origin.password': 'Password awal',
  'individual.center.new.password': 'Password baru',
  'individual.center.confirm.password': 'Konfirmasi password',
  'individual.center.all': 'Semua ',
  'individual.center.studying': 'Sedang Belajar',
  'individual.center.finished': 'Sudah selesai dipelajari',
  'individual.center.correcting': 'Sedang dikoreksi',
  'individual.center.corrected': 'Sudah dikoreksi',
  'individual.center.wart': 'Tugas kamu sedang diperiksa,  harap bersabar.',
  'individual.favorite.modal.title': 'Batal menyimpan',
  'individual.favorite.modal.content': 'Apakah batal menyimpan',
  //登录弹框
  'login.welcome': 'Selamat login',
  'login.welcome.desc': 'Selamat menggunakan situs pembelajaran Han Zhi Feng',
  'login.forgot.password': 'Lupa password',
  'login.sign.now': 'Masuk Sekarang',
  'login.no.account': 'Tidak punya akun',
  'login.register.now': 'Daftar Sekarang',
  'login.register.success': 'Pendaftaran Berhasil',
  'login.register.success.desc': 'Selamat, pendaftaran berhasil!',
  'login.not.login': 'Belum Masuk',
  'login.not.login.desc': 'Anda belum masuk ke akun, silahkan masuk dahulu',
  //注册弹框
  'register.welcome': 'Selamat mendaftar',
  'register.fetch.code': 'Dapatkan Kode Verifikasi',
  'reset.come.mind': 'Sudah ingat password',
  //未购买弹框
  'buy.dialog.title': 'Anda sekarang belum membeli pembelajaran tersebut, untuk dapat mempelajari pembelajaran tersebut, silahkan membeli terlebih dahulu',
  'buy.dialog.look': 'Nanti saja',
  //意见反馈弹框
  'feedback.title': 'Saran dan Respon',
  'feedback.btn.proposal': 'Memberi Saran',
  'feedback.btn.defect': 'Memberi Kritik',
  'feedback.btn.want': 'Saran dan Respon',
  'feedback.text': 'Jika kamu adalah seorang manager produk, kamu ada masukan apa?',
  'feedback.problem': 'Masalah saat ini',
  'feedback.problem.placeholder': 'Ceritakan secara detail permasalahan dan skenario yang dihadapi',
  'feedback.btn.cancel': 'Kirim nanti',

  //题目反馈弹框
  'topic.title': 'Saran dan Respon',
  'topic.btn.misspellings': 'Terdapat huruf yang salah',
  'topic.btn.audio.error': 'Kesalahan audio',
  'topic.btn.not.exist': 'Gambar tidak tersedia',
  'topic.btn.incomplete': 'Judulnya tidak lengkap',
  'topic.btn.other': 'Masalah lain',
  'topic.problem': 'Detail isi pengaduan',
  'topic.problem.text': 'Percobaan audio selesai ',
  //试听结束弹框
  'try.over.title': 'Percobaan audio selesai ',
  'try.over.desc': 'Masa percobaan audio anda sudah berakhir, untuk melanjutkan silahkan membeli.',
  //视频课详情
  'detail.course.classify': 'Penggolongan pelajaran',
  'detail.tag.video': 'Video',
  'detail.lessons.number': '{value} Bab pelajaran',
  'detail.course.price': 'Harga',
  'detail.course.characteristic.service': 'Layanan unggulan',
  'detail.course.brush.questions': 'Bahan latihan',
  'detail.course.online.consultation': 'Konsultasi online',
  'detail.course.buy.now': 'Segera beli',
  'detail.course.study.now': 'Segera belajar',
  'detail.course.consult.now': 'Konsul sekarang',
  'detail.course.course.details': 'Penjelasan pelajaran',
  'detail.course.course.chapter': 'Bab pelajaran',
  'detail.course.other.recommendations': 'Pelajaran lain yang disarankan',
  'detail.course.buy.title': 'Anda sedang membeli pelajaran bahasa mandarin Han Zhi Feng',
  'detail.welcome.consult': 'Selamat berkonsultasi',
  'detail.consult.tips': '"Tinggalkan kontak anda, akan ada guru profesional kami yang akan menghubungi anda',
  'detail.records.now': 'Daftar Sekarang',
  'detail.records.Play': 'Mainkan',
  'detail.records.try': 'uji siar',
  'detail.course.collect': 'Simpan',
  'detail.course.uncollect': 'Batal menyimpan',

  //视频播放页
  'player.course.collect': 'Pelajaran yang disimpan',
  'player.course.download.attachment': 'Mendownload lampiran',
  'player.course.catalogue': 'Daftar isi',
  'player.course.electronic.textbook': 'Bahan ajar e file',
  'player.course.electronic.word': '"Latihan Kosa kata" latihan dasar kosa kata (tersedia audio)',
  'player.course.chinese.pinyin': 'Romanisasi bahasa mandarin (Hanyu Pinyin)',
  'player.course.indonesian': 'Bahasa indonesia',
  'player.course.chinese': 'Bahasa mandarin',
  'player.course.close.pinyin': 'Tutup romanisasi Pinyin',
  'player.course.open.pinyin': 'Buka romanisasi Pinyin',
  'player.course.electronic.sentence': '"Latihan karangan"  latihan dasar  (karangan pendek, kalimat pendek)',
  'player.course.exercise': 'Latihan pelajaran',

  //练习结果页
  'practiceResult.count.questions': 'Total pertanyaan',
  'practiceResult.count.questions.unanswered': 'Jumlah pertanyaan yang belum di jawab',
  'practiceResult.answer.sheet': 'Kartu jawaban',
  'practiceResult.answer.correct': 'Benar',
  'practiceResult.answer.error': 'Salah',
  'practiceResult.answer.unanswered': 'Belum di jawab',
  'practiceResult.answer.answered': 'Sudah di jawab',
  'practiceResult.skip.practice': 'Latihan  yang di lewati',
  'practiceResult.full.mark': 'Nilai full {value}',
  'practiceResult.submit.now': 'kirim segera',

  //课程分类
  'all.course.find': `Total ditemukan <span>{value}</span> buah hasil`,
  'all.course.sort.synthesis': 'Komprehensif',
  'all.course.sort.popularity': 'Popularitas',

  //表单
  'form.placeholder.email': 'Silahkan masukkan alamat email anda',
  'form.placeholder.password': 'Silahkan masukkan password anda',
  'form.placeholder.confirm.password': 'Silahkan konfirmasi password anda',
  'form.placeholder.code': 'Silahkan masukkan kode verifikasi anda',
  'form.placeholder.nickname': 'Silahkan masukkan user name',
  'form.placeholder.origin.password': 'Silahkan masukkan password awal',
  'form.placeholder.new.password': 'Silahkan masukkan password baru',
  'form.placeholder.new.username': 'Silahkan masukkan nama lengkap anda',
  'form.placeholder.new.mobile': 'Silahkan masukkan nomor telepon anda',
  'form.placeholder.search': 'Cari pelajaran bahasa mandarin',
  'form.placeholder.textarea': 'Silahkan jawab di sini',
  'form.reset.password': 'Ganti password',
  'form.has.account': 'Sudah memiliki akun',

  //校验
  'form.password.unlikeness': 'Dua kali memasukkan password yang berbeda',
  'form.email.validate': 'Format alamat email tidak tepat',
  'form.password.validate': '"Password terdiri dari 8 - 16 digit, terdiri dari abjad huruf besar, huruf kecil, angka atau tanda baca' + '"',

  //作业、题目
  'exercise.Multiple-choice.question': 'Soal pilihan ganda',
  'exercise.Multiple.choice.question': 'Soal multiple choices',
  'exercise.true.false questions': 'Soal benar atau salah',
  'exercise.subjective.questions': 'Soal  subyektif',
  'exercise.reading.comprehension.questions': 'Pemahaman wacana',
  'exercise.reading.correct.answer': 'Jawaban yang tepat',
  'exercise.reading.your.answer': 'Jawaban Anda',
  'exercise.reading.view.parsing': 'Memeriksa analisa',
  'exercise.reading.next.question': 'Soal berikutnya',
  'exercise.reading.pack.up': 'Simpan',

  //接口返回码
  'code.1001': 'Format alamat email salah, silahkan isi alamat email yang tepat',
  'code.1004': 'Kode verifikasi salah',
  'code.1003': 'Alamat email sudah terdaftar, silahkan ganti alamat email',
  'code.1011': 'Ganti password berhasil',
  'code.1010': 'Password  awal salah',
  'code.1009': 'Perubahan data berhasil',
  'code.1013': 'Ganti password berhasil',
  'code.1006': 'User id atau password salah',
  'code.1014': 'Selamat, Anda sudah berhasil membeli Mata Pelajaran .',
  'code.1016': 'Pertanyaan Anda sudah kami terima',
  'code.1017': 'Pertanyaan umpan balik  sudah terkirim',
  'code.1019': 'Latihan Anda sudah terkirim, silahkan cek di "Latihan Saya"',
  //激活
  'code.1028': 'Aktivasi pembelajaran berhasil',
  'code.1027': 'Aktivasi pembelajaran salah',
  'code.1026': 'Kode aktivasi sudah digunakan',
  'code.1025': 'Kode aktivasi tidak ada',

  //中华文化
  'home.latest.news': 'Kebudayaan China',
  'culture.center.more': 'Info lebih lanjut',
  'culture.center.subTitle': 'Biar lah China menuju ajang Internasional, Biarlah Dunia memahami China.',
  'culture.center.search': 'Masukkan kata kunci dari pencarian yang Anda cari',
  'culture.center.text': 'Pusat kebudayaan',
  'culture.center.btn': 'Memeriksa penjelasan',
  'hzf.login.hint': 'Petunjuk',
  'detail.course.download.anytime': 'Unduh lampiran',
  'hzf.login.agree': 'Setuju dan login',
  'hzf.login.pre.read': 'Silahkan baca dan setuju sebelum Anda login',
  'hzf.register.pre.read': 'Silahkan baca dan setuju sebelum Anda daftar',
  'hzf.login.agree.register': 'Setuju dan daftar',

  //词典新增
  'dictionaries.glossary.1': 'Kamus Han Zhi Feng',
  'dictionaries.glossary.2': 'Menjelajah Bahasa Mandarin, dan mencerahkan perjalanan bahasa Anda',
  'dictionaries.glossary.3': 'Pusat kosa kata',
  'dictionaries.glossary.4': 'Silahkan masukkan kosa kata yang ingin Anda cari ',
  'dictionaries.glossary.5': 'Pusan kosa kata',
  'dictionaries.glossary.6': 'Pin Yin',
  'dictionaries.glossary.7': 'Cotoh kalimat',
  'dictionaries.glossary.8': 'Bahasa Inggris',
  'dictionaries.glossary.9': 'Jelajahi kosa kata',
  'dictionaries.glossary.10': 'Tingkat 1',
  'dictionaries.glossary.11': 'Tingkat 2',
  'dictionaries.glossary.12': 'Tingkat 3',
  'dictionaries.glossary.13': 'Tingkat 4',
  'dictionaries.glossary.14': 'Tingkat 5',
  'dictionaries.glossary.15': 'Tingkat 6',

  'currency.symbol': '$',

  //动态替换语言
  'hzf.variable.word': 'Bahasa indonesia',

  'activation.code.text01': 'Kode aktivasi untuk membuka',
  'activation.code.text02': 'Masukkan kode aktivasi untuk mengaktifkan pembelajaran',
  'activation.code.text03': 'Masukkan kode aktivasi untuk mengaktifkan pembelajaran',
  'activation.code.text04': 'Menuju pembelian',
  'activation.code.text05': 'Segera aktivasi',
  'activation.code.text06': 'Selamat! Aktivasi berhasil !',
  'activation.code.text07': 'Lain kali baru lihat',
  'activation.code.text08': 'Anda sudah berhasil mengaktifkan pembelajaran bahasa Mandarin, Mulailah ber eksperimen!',

  'video.reload.text': 'Nonton Ulang',
  'qrcode.text': 'Scan,Down load APP',

  //我们的优势
  'swiperList.01.title': 'efisiensi dan kualitas pengajaran',
  'swiperList.01.desc': 'membantu guru mengoptimalkan strategi pengajaran dan meningkatkan kualitas pengajaran',
  'swiperList.01.desc01':
    'guru dapat mempublikasikan materi pengajaran, memberi tugas, memeriksa tugas dan lain-lain di situs pendidikan untuk mengurangi pekerjaan yang berulang-ulang guna meningkatkan kualitas kerja.',
  'swiperList.02.title': 'belajar fleksibel dan nyaman',
  'swiperList.02.desc': 'Han Zhi Feng Mandarin tidak dibatasi oleh ruang dan waktu pendidikan tradisonal',
  'swiperList.02.desc01':
    'Han Zhi Feng Mandarin menyediakan materi online yang padat, siswa dapat kapan saja mengunjungi dan mulai belajar, tidak perlu menunggu jadwal belajar baru mulai atau mengatur waktu khusus untuk belajar.',
  'swiperList.03.title': 'team kami',
  'swiperList.03.desc': 'Han Zhi Feng Mandarin  terbentuk dari berbagai elite kelompok dari  berbagai bidang',
  'swiperList.03.desc01':
    'Han Zhi Feng Mandarin  terbentuk dari elite kelompok  bidang pendidikan, ilmu pengetahuan dan teknologi, desain dan bidang lainnya. Mereka tidak hanya kaya akan pengetahuan keahlian, tspi juga sangat mencintai dan bertanggung jawab terhadap bidang pengajaran. Setiap tautan semuanya merupakan kerja keras dan kebijakan anggota team.',
  'swiperList.04.title': 'interaksi dan komunikasi',
  'swiperList.04.desc': 'Kurikulum Han Zhi Feng Mandarin  mempertahankan interaksi langsung dengan siswa',
  'swiperList.04.desc01':
    'Kurikulum Han Zhi Feng Mandarin  mempertahankan interaksi langsung dengan siswa, bersamaan dengan adanya topik dan latihan soal diakhir  pelajaran dan metode lainnya untuk menyelesaikan proses pembelajaran, memastikan hasil belajar siswa.',
  'swiperList.05.title': 'materi belajar individual yang padat',
  'swiperList.05.desc': 'Han Zhi Feng Mandarin mengumpulkan berbagai jenis materi pendidikan bahasa mandarin',
  'swiperList.05.desc01':
    'Han Zhi Feng Mandarin mengumpulkan berbagai jenis materi pendidikan bahasa mandarin, melalui sistem rekomendasi yang cerdas, situs pendidikan dapat merekomendasikan materi dan pendekatan pembelajaran yang dipersonalisasikan kepada siswa berdasarkan kemajuan belajar siswa, minat, preferensi dan informasi lainnya.',

  //直播课
  'live.message.01': 'Pelajaran siaran langsung',
  'live.message.02': 'Penjelasan siaran langsung',
  'live.message.03': 'Waktu Beijing',
  'live.message.04': 'Guru siaran langsung',
  'live.message.05': 'Siaran langsung',
  'live.message.06': 'Waktu mulai pelajaran',
  'live.message.07': 'Pelajaran eksklusif',
  'live.message.08': 'Pengajar ternama',
  'live.message.09': 'Interaksi langsung ',
  'live.message.10': 'Penjelasan siaran langsung',
  'live.message.11': 'Pelajaran siaran langsung lainnya',
  'live.message.12': 'Informasi pemerintah sudah ditetapkan tidak boleh diubah.',

  //注册加国家
  // 'registry.message.01': 'Silahkan pilih negara anda！',
  // 'registry.message.02': 'Saat ini sementara belum dijadwalkan pelajaran live',
  // 'registry.message.03': 'Negara',

  //支付
  'payment.message.01': 'Cara bayar',
  'payment.message.02': 'Durasi pembelian',
  'payment.message.03': 'Pembayaran gagal! Silahkan mengulangi pembayaran.',
  'payment.message.04': 'Satu bulan',
  'payment.message.05': 'Tiga bulan',
  'payment.message.06': 'Enam bulan',
  'payment.message.07': 'Setahun',
  'payment.message.08': 'Lima tahun',

  //邀请好友
  'share.message.01': 'undang teman',
  'share.message.02': 'segera salin',
  'share.message.03': 'salin berhasil',
  'share.message.04': 'daftar undang',
  'share.message.05': 'akun orang yang di undang',
  'share.message.06': 'waktu pendaftaran',

  //版权所有，盗摄必究
  'copyright.message.01': 'Hak cipta dilindungi',
  'copyright.message.02': 'Hak dilindungi UU',

  //网站名称
  'website.message.01': 'HanZhiFeng',
};
