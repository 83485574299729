/**
 * 字典
 */
export const dict = {
  language: [
    {
      value: 'zh',
      label: '简体中文',
    },
    {
      value: 'en',
      label: 'English',
    },
    {
      value: 'id',
      label: 'Indonesia',
    },
    {
      value: 'ar',
      label: 'العربية',
    },
    {
      value: 'pk',
      label: 'اردو',
    },
  ],
};

export const langToBack = {
  zh: 'zh',
  en: 'en',
  id: 'id',
  ar: 'ar',
  pk: 'pk',
};

export const courseType = [
  { label: 'common.all.course.charge', value: '1' },
  { label: 'common.all.course.free', value: '2' },
];

/**
 * 获取字典value
 * @param dictName
 * @param key
 * @returns {*}
 */
export function useGetDictValue(dictName, key) {
  return dict[dictName][key];
}

/**
 * 获取字典key
 * @param dictName
 * @param value
 * @returns {string}
 */
export function useGetDictKey(dictName, value) {
  return dict[dictName].find((k) => {
    return k.value === value;
  }).label;
}
