<!--登录-->
<template>
  <a-modal
    v-model:visible="loginVisible"
    :closable="true"
    :esc-to-close="false"
    :mask-closable="false"
    :style="{ '--successGif': successGif }"
    :unmount-on-close="true"
    modal-class="login_modal"
    @before-close="beforeClose"
  >
    <p class="welcome">{{ use$t('login.welcome') }}</p>
    <p class="welcome_desc">{{ use$t('login.welcome.desc') }}~</p>
    <a-form v-if="successGif == 'none'" ref="formRef" :model="formParams" :rules="rules" layout="vertical" @submit="handleSubmit">
      <a-form-item field="email" validate-trigger="blur">
        <a-input v-model="formParams.email" :placeholder="use$t('form.placeholder.email')" allow-clear />
      </a-form-item>
      <a-form-item field="password" validate-trigger="blur">
        <a-input-password v-model="formParams.password" :placeholder="use$t('form.placeholder.password')" allow-clear />
      </a-form-item>
      <a-form-item class="forget_password">
        <a-typography-text class="pointer" type="primary" @click="forgetPasswordHandle">
          {{ use$t('login.forgot.password') }}
        </a-typography-text>
      </a-form-item>
      <a-form-item v-if="successGif == 'none'" class="login_now">
        <a-button :loading="loading" class="login_now_btn" html-type="submit">{{ use$t('login.sign.now') }}</a-button>
      </a-form-item>
    </a-form>
    <div v-if="successGif == 'none'" class="protokol">
      <a-checkbox v-model="userCheckBox">
        <div class="protokol_text">
          {{ use$t('common.protokol.privasi01') }}
          <span @click.prevent="useToTabPage('/agreement')">{{ use$t('common.protokol.privasi02') }}</span>
        </div>
      </a-checkbox>
    </div>
    <template #footer>
      <p v-if="successGif == 'none'" class="no_account">
        {{ use$t('login.no.account') }}？
        <span @click="registerNow">{{ use$t('login.register.now') }}</span>
      </p>
      <div v-else></div>
    </template>
  </a-modal>
  <Confirm ref="confirmRef" :ok-event="agreeLoginEvent" :ok-text="use$t('hzf.login.agree')" :title="use$t('hzf.login.hint')">
    <p>{{ use$t('hzf.login.pre.read') }}</p>
    <a-link @click="useToTabPage('/agreement')">{{ use$t('footer.privacy.agreement') }}</a-link>
  </Confirm>
</template>

<script setup>
import { computed, reactive, ref } from 'vue';
import { cloneDeep } from 'lodash';
import { use$t } from '@/hooks/language.js';
import RegUtils from '@/common/RegUtils.js';
import request from '@/common/request.js';
import { useGetUserinfo } from '@/hooks/commomApi.js';
import { useRefreshPage, useToTabPage } from '@/hooks/page.js';
import Confirm from '@/components/common/Confirm/Confirm.vue';

const loading = ref(false);
const successGif = ref('none');
const userCheckBox = ref(false);
const confirmRef = ref();
const formRef = ref();

const $props = defineProps({
  loginVisible: {
    type: Boolean,
    default: false,
  },
  registerVisible: {
    type: Boolean,
    default: false,
  },
  forgetVisible: {
    type: Boolean,
    default: false,
  },
});
const $emits = defineEmits(['update:loginVisible', 'update:registerVisible', 'update:forgetVisible']);
const loginVisible = computed({
  get: () => {
    return $props.loginVisible;
  },
  set: (val) => {
    $emits('update:loginVisible', val);
  },
});
const registerVisible = computed({
  get: () => {
    return $props.registerVisible;
  },
  set: (val) => {
    $emits('update:registerVisible', val);
  },
});
const forgetVisible = computed({
  get: () => {
    return $props.forgetVisible;
  },
  set: (val) => {
    $emits('update:forgetVisible', val);
  },
});
const formParams = reactive({
  email: '',
  password: '',
});
const copyFormParams = reactive(cloneDeep(formParams));
const rules = reactive({
  email: [
    {
      required: true,
      message: use$t('form.placeholder.email'),
    },
    {
      validator: (value, cb) => {
        if (RegUtils.RegEmail(value)) {
          cb(use$t(use$t('form.email.validate')));
        } else {
          cb();
        }
      },
    },
  ],
  password: [
    {
      required: true,
      message: use$t('form.placeholder.password'),
    },
    {
      validator: (value, cb) => {
        if (RegUtils.RegPw(value)) {
          cb(use$t(use$t('form.password.validate')));
        } else {
          cb();
        }
      },
    },
  ],
});

/**
 * 同意并登录
 * @returns {boolean}
 */
const agreeLoginEvent = () => {
  userCheckBox.value = true;
  handleSubmit({
    errors: undefined,
    values: {},
  });
  return true;
};

/**
 * 提交
 * @param errors
 * @param values
 */
const handleSubmit = ({ errors, values }) => {
  if (errors) return;
  if (userCheckBox.value == false) {
    confirmRef.value.openConfirm();
    return;
  }
  loading.value = true;
  request({
    url: '/Auth/Login',
    method: 'get',
    params: {
      email: formParams.email,
      pwd: formParams.password,
    },
  })
    .then((resp) => {
      localStorage.setItem('Token', resp.data);
      loading.value = false;
      useGetUserinfo(() => {
        //打开动画
        successGif.value = 'block';
        setTimeout(() => {
          loginVisible.value = false;
          useRefreshPage();
        }, 2000);
      });
    })
    .catch((err) => {
      loading.value = false;
    });
};

/**
 * 打开忘记密码
 */
const forgetPasswordHandle = () => {
  loginVisible.value = false;
  forgetVisible.value = true;
};

/**
 * 打开注册
 */
const registerNow = () => {
  registerVisible.value = true;
  loginVisible.value = false;
};

const beforeClose = () => {
  Object.assign(formParams, copyFormParams);
};

defineExpose({
  openLogin: () => {
    loginVisible.value = true;
  },
});
</script>

<style lang="less">
.login_modal {
  width: 600px;
  min-height: 500px;
  border-radius: 8px;
  background-image: url('@/assets/images/login_bg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;

  &:before {
    display: var(--successGif);
    height: 500px;
    width: 600px;
    content: '';
    background-image: url('@/assets/images/login_success.gif');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: absolute;
    z-index: 10;
  }

  .protokol {
    text-align: center;
    margin-top: 10px;

    .protokol_text {
      span {
        color: #0678ff;
      }
    }
  }

  .arco-modal-header {
    justify-content: flex-end;
    border-bottom: 0;

    .arco-icon-close {
      color: #999999;
      width: 22px;
      height: 22px;
    }
  }

  .arco-modal-body {
    padding: 8px 60px;

    .welcome {
      font-family: PingFang SC;
      font-weight: 600;
      font-size: 34px;
      color: #000000;
      line-height: 48px;
      text-align: left;
      font-style: normal;
    }

    .welcome_desc {
      font-family: PingFang SC;
      font-weight: 400;
      font-size: 18px;
      color: #000000;
      line-height: 25px;
      text-align: left;
      font-style: normal;
      margin-top: 20px;
    }

    .arco-form {
      margin-top: 30px;

      .arco-row-align-start {
        height: 48px;
        margin: 0;

        &:nth-child(2) {
          margin-top: 20px;
        }
      }

      .arco-input-wrapper {
        width: 100%;
        height: 48px;
        background: #ffffff;
        border-radius: 6px;
        border: 1px solid #cdcdcd;
      }

      .forget_password {
        margin-top: 10px;

        .arco-form-item-content {
          justify-content: flex-end;
        }
      }

      .login_now {
        .login_now_btn {
          width: 100%;
          height: 48px;
          background: linear-gradient(270deg, #0eb0ff 0%, #0678ff 100%);
          border-radius: 6px;
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #ffffff;
          line-height: 22px;
          text-align: center;
          font-style: normal;
        }
      }
    }

    .arco-form-item-label-col {
      display: none;
    }
  }

  .arco-modal-footer {
    border: 0;
    margin-top: 10px;

    .no_account {
      font-family: PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #999999;
      line-height: 20px;
      text-align: center;
      font-style: normal;

      span {
        color: #0678ff;
        cursor: pointer;
      }
    }
  }
}
</style>
